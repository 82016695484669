import Lottie from "react-lottie";
import Flower from "./../../assets/json/flower.json";
import { useTranslation } from "react-i18next";

const Content = () => {
  const [t, i18n] = useTranslation();
  const DefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Flower,
    hover: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className='fixed z-50 top-0 left-0  w-[100vw] h-[100vh] hidden items-center justify-center '
      id='content'>
      <div className='container max-w-[450px]'>
        <div className='mob_md:p-4 p-4 bg-light dark:bg-dark shadow-xl text-dark dark:text-white dark:border-yellow flex flex-col items-center border-2  rounded-xl  w-[100%]'>
          <div className='flex flex-col  w-[100%]'>
            <span
              onClick={() => {
                document.querySelector("#content").style = `display: none`;
              }}
              className='close self-end px-3 py-2  cursor-pointer rounded-md'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                className='nav-svg-red'
                viewBox='0 0 23.432 23.432'>
                <g id='close' transform='translate(-364.284 -187.624)'>
                  <path
                    id='Vector'
                    d='M21.062,21.062,0,0'
                    transform='translate(365.593 188.685)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                  />
                  <path
                    id='Vector-2'
                    data-name='Vector'
                    d='M21.062,0,0,21.062'
                    transform='translate(365.345 188.933)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                  />
                </g>
              </svg>
            </span>
            <div className='w-[100%] flex justify-center items-center'>
              <div className='w-36 relative'>
                <Lottie options={DefaultOptions} />
                <div className='absolute top-0 left-0 w-[100%] h-[100%] '></div>
              </div>
            </div>
            <h1 className='text-xl font-bold self-start mb-5 '>
              {t("form.content")}
            </h1>
          </div>
          <form method='POST' className='flex flex-col items-center gap-y-5'>
            <div className='w-[100%] flex sf-y'>
              <span className='flex items-center justify-center cursor-pointer bg-yellow dark:bg-dark text-dark dark:text-white dark:border-yellow px-3 border-2 border-r-0 rtl:border-r-2 rtl:border-l-0 rounded-md rounded-tl-md rounded-bl-md rounded-br-none rounded-tr-none rtl:rounded-bl-none rtl:rounded-tl-none rtl:rounded-tr-md rtl:rounded-br-md'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  className='nav-svg'>
                  <g
                    id='vuesax_linear_sms'
                    data-name='vuesax/linear/sms'
                    transform='translate(-556 -250)'>
                    <g id='sms'>
                      <path
                        id='Vector'
                        d='M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z'
                        transform='translate(558 253.5)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-2'
                        data-name='Vector'
                        d='M10,0,6.87,2.5a3.166,3.166,0,0,1-3.75,0L0,0'
                        transform='translate(563 259)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-3'
                        data-name='Vector'
                        d='M0,0H24V24H0Z'
                        transform='translate(556 250)'
                        fill='none'
                        opacity='0'
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <input
                className='input  w-[100%] bg-light dark:bg-dark text-dark dark:text-light dark:border-yellow border-2  rounded-md rounded-tl-none rounded-bl-none  rtl:rounded-bl-md rtl:rounded-tl-md rtl:rounded-tr-none rtl:rounded-br-none '
                type='email'
                placeholder={t("form.email")}
              />
            </div>
            <div className='w-[100%] flex sf-y'>
              <span className='flex items-center justify-center cursor-pointer bg-yellow dark:bg-dark text-dark dark:text-white dark:border-yellow px-3 border-2 border-r-0 rtl:border-r-2 rtl:border-l-0 rounded-md rounded-tl-md rounded-bl-md rounded-br-none rounded-tr-none rtl:rounded-bl-none rtl:rounded-tl-none rtl:rounded-tr-md rtl:rounded-br-md'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  className='nav-svg'>
                  <g
                    id='vuesax_linear_path'
                    data-name='vuesax/linear/path'
                    transform='translate(-490 -254)'>
                    <g id='path' transform='translate(490 254)'>
                      <path
                        id='Vector'
                        d='M8.21,3.854,5.18.824C4.03-.326,2.46-.266,1.69.964L0,3.634l5.4,5.4,2.67-1.69A2.231,2.231,0,0,0,8.21,3.854Z'
                        transform='translate(11.58 3.416)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-2'
                        data-name='Vector'
                        d='M8.133.256,4.2.016c-2.19-.13-2.96.54-3.2,2.63l-.98,8.32a2.409,2.409,0,0,0,2.8,2.8l8.32-.98c2.09-.25,2.85-1.01,2.63-3.2l-.23-3.93'
                        transform='translate(3.447 6.794)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-3'
                        data-name='Vector'
                        d='M0,3.04,3.03,0'
                        transform='translate(4.61 16.38)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-4'
                        data-name='Vector'
                        d='M0,0H24V24H0Z'
                        fill='none'
                        opacity='0'
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <input
                className='input w-[100%] bg-light dark:bg-dark text-dark dark:text-light dark:border-yellow border-2  rounded-md rounded-tl-none rounded-bl-none rtl:rounded-bl-md rtl:rounded-tl-md rtl:rounded-tr-none rtl:rounded-br-none '
                type='text'
                placeholder={t("form.subject")}
              />
            </div>

            <textarea
              className='input sf-y w-[100%]  border-2 rounded-md bg-light dark:bg-dark text-dark dark:text-white dark:border-yellow'
              placeholder={t("form.message")}
              rows={4}
              cols={200}></textarea>
            <input
              className='input sf-y self-end cursor-pointer bg-yellow rounded-md dark:text-black'
              type='submit'
              value={t("form.send")}
            />
          </form>
        </div>
      </div>
      <div
        onClick={() => {
          document.querySelector("#content").style = `display: none`;
        }}
        className='bg-light-overlaye backdrop-blur-sm dark:bg-dark-overlaye
        fixed -z-10 w-[100vw] h-[100vh]'></div>
    </div>
  );
};

export default Content;
