import Lottie from "react-lottie";
import Rocket_lottie from "./../../../assets/json/Rocket.json";
import { ClassToGroups } from "../../../assets/js/awds";
const Rocket = () => {
  const DefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Rocket_lottie,
    hover: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  ClassToGroups("animate__fadeInUp", ".fadeInUp");
  return (
    <div
      className='relative
    bottom-40
    mob_smx:bottom-32
    scr_sm:-bottom-32
    scr_sm:-right-5
    scr_mdx:-right-40 
    scr_lg:-right-48 

    scr_sm:rtl:right-5
    scr_mdx:rtl:right-40 
    scr_lg:rtl:right-48 
    lottie    
  
    w-[100vw]
    animate__animated 
    fadeInUp 
    animate__slow 
    
    '>
      <div
        className='
      absolute
      w-[100%] 
      h-[100%]
      z-10
      
      '></div>
      <Lottie options={DefaultOptions} />
    </div>
  );
};
export default Rocket;
