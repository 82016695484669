import { useEffect, useState } from "react";
import NavLink from "./NavLink";
import logoLight from "./../../assets/img/logoLight.svg";
import logoDark from "./../../assets/img/logoDark.svg";
import Moon from "./../../assets/img/icons/Moon.svg";
import Sun from "./../../assets/img/icons/Sun.svg";
import { useTranslation } from "react-i18next";
import Content from "./Content";

const Header = () => {
  const [theme, setTheme] = useState(true);
  const [themeToggle, setThemeToggle] = useState(true);
  const Toggle = () => {
    theme ? setTheme(false) : setTheme(true);
    themeToggle ? setThemeToggle(false) : setThemeToggle(true);
  };
  const [actived, setActived] = useState();
  const SetActive = () => {
    const li = document.querySelectorAll("nav ul li");
    li.forEach((e) => {
      e.target.current.onclick = () => {
        console.log("clecked");
        e.target.current.style = `color: red`;
      };
    });
  };
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState(true);
  const toggleLang = () => {
    if (lang) {
      setLang(false);
      document.documentElement.setAttribute("dir", "rtl");
      i18n.changeLanguage("ar");
    } else {
      setLang(true);
      document.documentElement.setAttribute("dir", "ltr");
      i18n.changeLanguage("en");
    }
  };
  return (
    <>
      <div id='top'></div>

      <header className='bg-gradient-to-b from-white dark:from-black to-leanir-light dark:to-leanir-dark  py-6 text-[.8rem] pb-16 relative z-50'>
        <div className='container flex items-center justify-between'>
          <div className='w-[7rem]'>
            <img src={themeToggle ? logoLight : logoDark} alt='awds Logo' />
            {/* <UserAgent light={logoLight} dark={logoDark} alt='AWDSD Logo' /> */}
          </div>
          {/* {useEffect(() => {
            document.body.clientWidth > 1024 && ( */}
          <nav>
            {/* <ul className='hidden gap-10 scr_sm:flex' onClick={SetActive}> */}
            <ul className='gap-10 hidden tab_sm:flex' onClick={SetActive}>
              <NavLink />
            </ul>
          </nav>
          {/* );
          })} */}
          <div className='flex items-center gap-x-5'>
            <button
              onClick={toggleLang}
              className=' sf-x hidden tab_sm:flex underline font-bold dark:text-light underline-offset-8 font-Almarai-AR rtl:font-Nunito mx-10'>
              {lang ? "عربي" : "English"}
            </button>
            <button
              onClick={() => {
                Toggle();
                themeToggle
                  ? document.documentElement.classList.add("dark")
                  : document.documentElement.classList.remove("dark");
              }}
              className={`mx-10  sf-x hidden tab_sm:flex items-center gap-x-4 btn shadow-md  ${
                theme
                  ? "bg-dark  shadow-shadow-yellow"
                  : "bg-white shadow-shadow-white"
              }`}>
              <span
                className={`border-r-2 rtl:border-l-2 rtl:border-r-0 pr-4 rtl:pl-4 rtl:pr-0 ${
                  theme ? "border-yellow" : "border-dark"
                }`}>
                {/* <UserAgent light={Moon} dark={Sun} alt='Planet Icon' /> */}
                <img src={theme ? Moon : Sun} alt='Planet Icon' />
              </span>
              <span
                className={`theme font-bold ${
                  theme ? "text-yellow" : "text-dark"
                }`}>
                {theme ? t("dark") : t("light")}
              </span>
            </button>
            <button onClick={toggleLang} className=' mx-10 sf-x tab_sm:hidden'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                className='nav-svg'
                viewBox='0 0 24 24'>
                <g transform='translate(-748 -316)'>
                  <path
                    d='M4.28,4.27,2.14,0,0,4.27'
                    transform='translate(762.78 330.4)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                  />
                  <path
                    d='M0,0H3.52'
                    transform='translate(763.17 333.91)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                  />
                  <path
                    d='M5.08,10.16a5.08,5.08,0,1,1,5.08-5.08A5.081,5.081,0,0,1,5.08,10.16Z'
                    transform='translate(759.84 327.84)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                  />
                  <path
                    d='M3.02,0H6.94c2.07,0,3.07,1,3.02,3.02V6.94c.05,2.07-.95,3.07-3.02,3.02H3.02C1,10,0,9,0,6.93V3.01C0,1,1,0,3.02,0Z'
                    transform='translate(750 318)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                  />
                  <path
                    d='M0,0A7,7,0,0,0,7,7L5.95,5.25'
                    transform='translate(750 331)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                  />
                  <path
                    d='M7,7A7,7,0,0,0,0,0L1.05,1.75'
                    transform='translate(763 318)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                  />
                  <path
                    d='M0,0H24V24H0Z'
                    transform='translate(772 340) rotate(180)'
                    fill='none'
                    opacity='0'
                  />
                  <path
                    d='M-2562.124-2202.38s-2.156-.589-1.827.952a1.293,1.293,0,0,0,1.827.952s-3.093,1.5-2.062,3.219,4.264,0,4.264,0'
                    transform='translate(3317.551 2522.706)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeWidth='0.8'
                  />
                </g>
              </svg>
            </button>
            <button
              className={`mx-10 ${
                theme ? "bg-dark" : "bg-white"
              } tab_sm:hidden p-2 sf-x rounded-full`}
              onClick={() => {
                Toggle();
                themeToggle
                  ? document.documentElement.classList.add("dark")
                  : document.documentElement.classList.remove("dark");
              }}>
              <img src={theme ? Moon : Sun} width='15' alt='Planet Icon' />
            </button>
            <button
              onClick={() => {
                document.querySelector("#content").style = `display: flex`;
              }}
              className='mx-10 sf-x hidden tab_sm:flex btn  border-light shadow-yellow dark:text-yellow dark:border-yellow hover:border-dark hover:shadow-lg hover:dark:shadow-yellow hover:dark:shadow-md '>
              <a href='#'>{t("content-with-me")}</a>
            </button>
            <button
              onClick={() => {
                document.querySelector("#content").style = `display: flex`;
              }}
              className='mx-10 btn tab_sm:hidden sf-x border-dark shadow-yellow dark:text-yellow dark:border-yellow hover:border-dark hover:shadow-lg hover:dark:shadow-yellow hover:dark:shadow-md '>
              <a href='#'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  className='nav-svg'>
                  <g transform='translate(-620 -250)'>
                    <path
                      d='M0,5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7c0,3.5-2,5-5,5H5'
                      transform='translate(622 253.5)'
                      fill='none'
                      stroke='#292d32'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                    />
                    <path
                      d='M10,0,6.87,2.5a3.166,3.166,0,0,1-3.75,0L0,0'
                      transform='translate(627 259)'
                      fill='none'
                      stroke='#292d32'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                    />
                    <path
                      d='M0,0H6'
                      transform='translate(622 266.5)'
                      fill='none'
                      stroke='#292d32'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                    />
                    <path
                      d='M0,0H3'
                      transform='translate(622 262.5)'
                      fill='none'
                      stroke='#292d32'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                    />
                    <path
                      d='M0,0H24V24H0Z'
                      transform='translate(620 250)'
                      fill='none'
                      opacity='0'
                    />
                  </g>
                </svg>
              </a>
            </button>
          </div>
        </div>
        <Content />
      </header>
    </>
  );
};
export default Header;
