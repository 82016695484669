import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ClassToGroups } from "../../assets/js/awds";
const NavLink = () => {
  const [t, i18n] = useTranslation();

  ClassToGroups("animate__fadeIn", ".fadeIn");
  return (
    <>
      <li
        className={`active btn flex gap-2 items-center dark:text-yellow hover:btn animate__animated fadeIn`}
        key={t("nav.id-1")}>
        <a href={t("nav.href-1")}>{t("nav.link-1")}</a>
        <span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21.407'
            height='21.407'
            viewBox='0 0 21.407 21.407'>
            <g transform='matrix(0.819, 0.574, -0.574, 0.819, -39.962, -341.79)'>
              <g transform='translate(236 252)'>
                <path
                  d='M4.152,1.1.246,8.518A2.048,2.048,0,0,0,2.974,11.3l2.075-1.038a2.048,2.048,0,0,1,1.832,0L8.956,11.3a2.049,2.049,0,0,0,2.728-2.786L7.777,1.1A2.047,2.047,0,0,0,4.152,1.1Z'
                  transform='translate(1.72 1.921)'
                  className='dark:fill-yellow'
                  fill='#fff'
                  stroke='#000'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M0,0H15.371V15.371H0Z'
                  transform='translate(15.371 15.371) rotate(180)'
                  fill='none'
                  opacity='0'
                />
              </g>
            </g>
          </svg>
        </span>
      </li>
      <li
        className={`${t(
          "nav.active-2"
        )} flex gap-2 items-center dark:text-yellow btn-nav animate__animated fadeIn  animate__delay-1s`}
        key={t("nav.id-2")}>
        <a href={t("nav.href-2")}>{t("nav.link-2")}</a>
        <span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='15.389'
            height='15.389'
            className='nav-svg'
            viewBox='0 0 15.389 15.389'>
            <g transform='translate(-682 -190)'>
              <g transform='translate(682 190)'>
                <path
                  d='M9.965,1.238a21.626,21.626,0,0,1-5.534,7.47L3.168,9.721a2.3,2.3,0,0,1-.5.289,2.391,2.391,0,0,0-.026-.365A2.511,2.511,0,0,0,1.9,8.176,2.618,2.618,0,0,0,.385,7.413,2.57,2.57,0,0,0,0,7.406a1.676,1.676,0,0,1,.314-.539l1-1.263A21.563,21.563,0,0,1,8.785.064a.9.9,0,0,1,.975.2A.871.871,0,0,1,9.965,1.238Z'
                  transform='translate(4.02 1.289)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M5.415,2.606A2.674,2.674,0,0,1,4.639,4.5a2.665,2.665,0,0,1-1.558.744L1.5,5.415A1.356,1.356,0,0,1,.01,3.908L.183,2.33A2.614,2.614,0,0,1,2.754,0a3.819,3.819,0,0,1,.385.006A2.581,2.581,0,0,1,4.652.772,2.511,2.511,0,0,1,5.4,2.241C5.4,2.362,5.415,2.484,5.415,2.606Z'
                  transform='translate(1.273 8.692)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M3.033,3.033A3.035,3.035,0,0,0,0,0'
                  transform='translate(6.098 6.245)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M4.264,0l.475.468a1.779,1.779,0,0,1,0,2.853l-1.9,1.9A1.78,1.78,0,0,1,0,5.22'
                  transform='translate(8.637 8.163)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeWidth='1'
                />
                <path
                  d='M.707,5.458a1.793,1.793,0,0,1,0-2.853L2.6.707a1.78,1.78,0,0,1,2.841,0l.475.475'
                  transform='translate(1.287 1.281)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeWidth='1'
                />
                <path
                  d='M2.373,0,0,2.373'
                  transform='translate(4.841 2.469)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeWidth='1'
                />
                <path
                  d='M1.9,0,0,1.892'
                  transform='translate(11.003 8.163)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeWidth='1'
                />
                <path d='M0,0H15.389V15.389H0Z' fill='none' opacity='0' />
              </g>
            </g>
          </svg>
        </span>
      </li>
      <li
        className={`${t(
          "nav.active-3"
        )} flex gap-2 items-center dark:text-yellow btn-nav animate__animated fadeIn animate__delay-2s`}
        key={t("nav.id-3")}>
        <a href={t("nav.href-3")}>{t("nav.link-3")}</a>
        <span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='17'
            height='17'
            className='nav-svg'
            viewBox='0 0 17 17'>
            <g transform='translate(-556 -252)'>
              <g transform='translate(556 252)'>
                <path
                  d='M1.27,0A2.987,2.987,0,0,0,.122,1.013a.728.728,0,0,0,0,.807A2.987,2.987,0,0,0,1.27,2.833'
                  transform='translate(5.105 9.208)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M0,0A2.987,2.987,0,0,1,1.147,1.013a.728.728,0,0,1,0,.807A2.987,2.987,0,0,1,0,2.833'
                  transform='translate(10.774 9.208)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M4.958,14.167h4.25c3.542,0,4.958-1.417,4.958-4.958V4.958C14.167,1.417,12.75,0,9.208,0H4.958C1.417,0,0,1.417,0,4.958v4.25C0,12.75,1.417,14.167,4.958,14.167Z'
                  transform='translate(1.417 1.417)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path
                  d='M0,.007,13.614,0'
                  transform='translate(1.58 5.667)'
                  fill='none'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1'
                />
                <path d='M0,0H17V17H0Z' fill='none' opacity='0' />
              </g>
            </g>
          </svg>
        </span>
      </li>
    </>
  );
};
export default NavLink;
