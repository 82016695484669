import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import DevicesLight from "./../../../assets/json/devices.json";
import Speed from "./../../../assets/json/speedy.json";
import Quality from "./../../../assets/json/quality.json";

// import React_Icon from "./../../../assets/img/React.svg";
const Let_Me = () => {
  const [t, i18n] = useTranslation();
  const DefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DevicesLight,
    hover: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const DefaultOptionsSpeed = {
    loop: true,
    autoplay: true,
    animationData: Speed,
    hover: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const DefaultOptionsQuality = {
    loop: true,
    autoplay: true,
    animationData: Quality,
    hover: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
      <div className='grid gap-10 lg:grid-cols-2'>
        <div className='flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg'>
          <div className='flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-teal-accent-400'>
            <svg className='text-teal-900 w-7 h-7' viewBox='0 0 24 24'>
              <polyline
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeMiterlimit='10'
                points=' 8,5 8,1 16,1 16,5'
                strokeLinejoin='round'
              />
              <polyline
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeMiterlimit='10'
                points='9,15 1,15 1,5 23,5 23,15 15,15'
                strokeLinejoin='round'
              />
              <polyline
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeMiterlimit='10'
                points='22,18 22,23 2,23 2,18'
                strokeLinejoin='round'
              />
              <rect
                x='9'
                y='13'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeMiterlimit='10'
                width='6'
                height='4'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='max-w-xl mb-6'>
            <h2 className='max-w-lg mb-6 font-sans rtl:font-Almarai-AR text text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none dark:text-white'>
              {t("let-us.let")}
              <br className='hidden md:block' />
              {t("let-us.your-next")}{" "}
              <span className='inline-block text-deep-purple-accent-400 dark:text-yellow'>
                {t("let-us.project")}
              </span>
            </h2>
            <p className='text-base text-gray-700 md:text-lg dark:text-gray-300'>
              {t("let-us.desc")}
            </p>
          </div>
          <div>
            <a
              href='/'
              aria-label=''
              className='inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 dark:text-yellow hover:text-deep-purple-800'>
              {t("let-us.order-now")}
              {document.dir === "rtl" ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  className='inline-block w-3 mr-2 nav-svg'
                  viewBox='0 0 24 24'>
                  <g
                    id='vuesax_linear_arrow-left'
                    data-name='vuesax/linear/arrow-left'
                    transform='translate(-364 -252)'>
                    <g id='arrow-left'>
                      <path
                        id='Vector'
                        d='M7.1,15.84.577,9.32a1.986,1.986,0,0,1,0-2.8L7.1,0'
                        transform='translate(371.902 256.08)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-2'
                        data-name='Vector'
                        d='M0,0H24V24H0Z'
                        transform='translate(388 276) rotate(180)'
                        fill='none'
                        opacity='0'
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  className='inline-block w-3 ml-2 nav-svg'
                  viewBox='0 0 24 24'>
                  <g
                    id='vuesax_linear_arrow-right'
                    data-name='vuesax/linear/arrow-right'
                    transform='translate(-300 -252)'>
                    <g id='arrow-right'>
                      <path
                        id='Vector'
                        d='M0,15.84,6.52,9.32a1.986,1.986,0,0,0,0-2.8L0,0'
                        transform='translate(308.91 256.08)'
                        fill='none'
                        stroke='#292d32'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                      <path
                        id='Vector-2'
                        data-name='Vector'
                        d='M0,0H24V24H0Z'
                        transform='translate(324 276) rotate(180)'
                        fill='none'
                        opacity='0'
                      />
                    </g>
                  </g>
                </svg>
              )}
            </a>
          </div>
        </div>
        <div
          className='flex items-center justify-center -mx-4 lg:pl-8'
          id='none-animation'>
          <div className='flex flex-col items-end px-3'>
            <div className='object-cover mb-6   h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56  relative'>
              <div className='overlay w-[100%] h-[100%]  absolute z-10  '></div>
              <Lottie options={DefaultOptionsSpeed} />
              {/* 
              <img
                className='object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56'
                src={React_Icon}
                alt=''
              /> */}
            </div>
            <div>
              <div className='object-cover w-20 h-20 rounded-[1.8rem] shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40 relative dark:shadow-slate-900 bg-[#ffdc69]  dark:shadow-[#ffd90096]'>
                <div className='overlay w-[100%] h-[100%]  absolute z-10 '></div>
                <Lottie options={DefaultOptionsQuality} />
              </div>
            </div>
          </div>
          <div className='px-3'>
            <div className='object-cover w-40 h-40 rounded-[1.8rem] shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80 bg-white relative '>
              <div className='overlay w-[100%] h-[100%]  absolute z-10 '></div>
              <Lottie options={DefaultOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Let_Me;
