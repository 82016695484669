import Rocket from "./Rocket";
import Text from "./Text";

const Hello = () => {
  return (
    <div className='relative mb-40 '>
      <div className='container flex justify-between relative h-[100vh] max-h-[950px] flex-col items-center scr_sm:flex-row '>
        <Text />
        <Rocket />
      </div>
      {/* <div className=' absolute -bottom-44 mob_smx:-bottom-52 mob_mdx:-bottom-64 hidden scr_sm:inline-block  scr_sm:-bottom-[800px] left-[50%] translate-x-[-50%] w-[150%] h-[900px] bg-white  rounded-[50%] scr_sm:-skew-x-[35deg] rtl:skew-x-[35deg] '></div> */}
    </div>
  );
};
export default Hello;
