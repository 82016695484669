// export const UserAgent = (props) => {
//   const Result =
//     window.matchMedia &&
//     window.matchMedia("(prefers-color-scheme: dark)").matches ? (
//       <img src={props.dark} alt={props.alt} />
//     ) : (
//       <img src={props.light} alt={props.alt} />
//     );
//   return <>{Result}</>;
// };

/* Function For Add Class To Group Elements 
    - this function added at V0.0.05
*/
/* Get Elements Group By [ ...elements ] & Get Target Class By [ Class ] */
export const ClassToGroups = (Class = "", ...elements) => {
  /* function add for using at window is load */
  const add = () => {
    /* Set All Elements [ ...elements ] At Variable */
    const allElements = document.querySelectorAll(`${elements}`);
    /* Loop At Elements To Set Target Class [ Class ] */
    allElements.forEach((element) => {
      /* Set Target Class [ Class ] At Elements [ element ] */
      element.classList.add(`${Class}`);
    });
  };
  /* Run Add Function When The Window Is Load */
  window.addEventListener("load", add);
};

/* Function For Slid 
    - this function added at V0.0.08
*/
