// TODO: Fix Safre Proplem With Gaping And Set Condeition if Browser Safre Set Margin x - 5
import { useTranslation } from "react-i18next";
import AboutMe from "./components/layout/AboutMe/AboutMe";
import Bg from "./components/layout/background/bg";
import Hello from "./components/layout/Hello/Hello";
import LetMe from "./components/layout/let_me/Let_me";
import Preloader from "./components/layout/Preloader/Preloader";
import Skills from "./components/layout/skills/skills";
import { Footer } from "./components/ui/footer";

// ui
import Header from "./components/ui/header";
import NavLinkMobile from "./components/ui/NavLinkMobile";

// layout
const App = () => {
  const [t, i18n] = useTranslation();
  return (
    <div
      className='bg-light dark:bg-dark h-[100vh] overflow-x-hidden font-Nunito rtl:font-Almarai-AR pb-6 scroll-smooth touch-auto relative
    '>
      <Bg />
      <Preloader />
      <Header />
      <Hello />
      <NavLinkMobile />

      <AboutMe />
      <Skills />
      <LetMe />
      <Footer />
    </div>
  );
};
export default App;
