import { useTranslation } from "react-i18next";
import SocailMedia from "./social-media";
import Morning from "./../../../assets/img/icons/Morning.svg";
import Night from "./../../../assets/img/icons/Night.svg";
import { ClassToGroups } from "./../../../assets/js/awds";
const Text = () => {
  const [t, i18n] = useTranslation();
  ClassToGroups("animate__fadeInUp", ".fadeInUp");
  return (
    <div className='self-center'>
      <div className='drop-shadow-md-light dark:drop-shadow-md-dark text-dark-blue dark:text-light text-[1.5rem] mob_sm:text-[1.8rem] mob_smx:text-[2rem] mob_md:text-5xl  font-semibold leading-[1.6] mob_md:leading-[1.6] m-0 mb-20 rtl:text-right '>
        <h1 className='Hi fadeInUp whitespace-nowrap animate__animated  '>
          {t("welcome.Hi")}
          {new Date().getHours() > 12 ? (
            <span>
              {t("welcome.Evening")}
              <img
                src={Night}
                className='w-12 inline font-Emoji'
                alt='Moon Icon'
              />
            </span>
          ) : (
            <span>
              {t("welcome.Morning")}
              <img
                src={Morning}
                className='w-12 h-12 inline font-Emoji animate-spin-slow'
                alt='Sun Icon'
              />
            </span>
          )}
        </h1>

        <h1 className='Welcome fadeInUp animate__animated    '>
          {t("welcome.Welcome")}{" "}
        </h1>
        <h1 className='To fadeInUp whitespace-nowrap animate__animated '>
          {t("welcome.To")}
          <span className='heart font-Emoji animate-ping-slow animate__animated  hert'>
            {" "}
            💕
          </span>
        </h1>
      </div>
      <div>
        <p className='content-me fadeInUp text-dark dark:text-light text-[1rem] animate__animated  '>
          {t("content-me")}
          <span className='font-Emoji text-2xl'>🌹</span>
        </p>
        <hr className='hr border-dark dark:border-light mt-2 animate__animated fadeInUp ' />
        <ul className=' flex flex-wrap scr_md:flex-nowrap justify-between mb-36 scr_sm:mb-0 relative z-[1] animate__animated fadeInUp '>
          <SocailMedia />
        </ul>
      </div>
    </div>
  );
};
export default Text;
