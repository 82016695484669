import { useEffect } from "react";
import Lottie from "react-lottie";
import Logo from "../../../assets/json/new_logo.json";
const Preloader = () => {
  useEffect(() => {
    const Loader = document.querySelector("#Loader");
    window.addEventListener("load", () => {
      Loader.style = `display : none`;
    });
  });
  const DefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Logo,
    hover: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div
        id='Loader'
        className='w-[100vw] h-[100vh] bg-light dark:bg-dark nav-svg fixed z-[999999999] flex items-center justify-center'>
        <div className='w-36 flex items-center  flex-col p-5'>
          <Lottie options={DefaultOptions} />
        </div>
      </div>
    </>
  );
};
export default Preloader;
