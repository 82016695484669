import { useTranslation } from "react-i18next";
import { ClassToGroups } from "../../../assets/js/awds";
const SocailMedia = (props) => {
  const [t, i18n] = useTranslation();
  ClassToGroups("animate__fadeInUp", ".fadeInUp");
  return (
    <>
      <li
        className='scr_sm:mt-4 mx-1 whatsapp animate__animated fadeInUp '
        key={t("socail.whatsapp-id")}>
        <a
          href={t("socail.whatsapp-href")}
          target='blank'
          className='socail-media-icon flex flex-col-reverse items-center mt-4'>
          <div className=' mt-2 w-12 h-12'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.5 51.5'>
              <g transform='translate(-139.25 -904.25)'>
                <rect
                  width='50'
                  height='50'
                  rx='5'
                  transform='translate(140 905)'
                  fill='#71b85c'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                />
                <g transform='translate(138.099 903.004)'>
                  <path
                    d='M23.17,26.1A10.08,10.08,0,0,0,33.236,36.191h.326a2.511,2.511,0,0,0,2.388-2.518,2.685,2.685,0,0,0-.043-.485,3.329,3.329,0,0,0-.145-.478l-4.241-.673a2.54,2.54,0,0,0-.579,1.281A7.569,7.569,0,0,1,26.1,28.593a2.526,2.526,0,0,0,1.288-.6l-.724-4.2a2.388,2.388,0,0,0-.586-.181,2.634,2.634,0,0,0-.412,0A2.518,2.518,0,0,0,23.17,26.1Z'
                    transform='translate(-2.561 -2.654)'
                    fill='#fff'
                  />
                  <path
                    d='M15.34,40.05a1.447,1.447,0,0,1-1.368-1.9l1.607-4.9a13.026,13.026,0,1,1,6.289,5.731L15.579,40.05A1.331,1.331,0,0,1,15.34,40.05Zm6.715-4.016a1.447,1.447,0,0,1,.622.145,10.131,10.131,0,1,0-4.3-3.85,1.447,1.447,0,0,1,.145,1.208l-1.064,3.242,4.342-.724Z'
                    transform='translate(0 0)'
                    fill='#fff'
                  />
                </g>
              </g>
            </svg>
          </div>
          <span className='tooltip'>{t("socail.whatsapp-name")}</span>
        </a>
      </li>
      <li
        className='linkedIn scr_sm:mt-4 mx-1 animate__animated fadeInUp'
        key={t("socail.linkedIn-id")}>
        <a
          href={t("socail.linkedIn-href")}
          target='blank'
          className='socail-media-icon flex flex-col-reverse items-center mt-4'>
          <div className=' mt-2 w-12 h-12'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.5 51.5'>
              <g transform='translate(-230.25 -904.25)'>
                <rect
                  width='50'
                  height='50'
                  rx='5'
                  transform='translate(231 905)'
                  fill='#a2c4f6'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                />
                <g transform='translate(-0.716 -0.056)'>
                  <path
                    d='M29.119,27.278a3.023,3.023,0,0,1-3.237,2.852,2.92,2.92,0,1,1,0-5.819,3.066,3.066,0,0,1,3.237,2.966ZM23.158,43.165V34a2.852,2.852,0,0,1,5.7,0v9.17a2.852,2.852,0,0,1-5.7,0Z'
                    transform='translate(221.086 894.751)'
                    fill='#1568e6'
                  />
                  <path
                    d='M45.687,34.658v6.475a2.852,2.852,0,0,1-3.194,2.852,3.009,3.009,0,0,1-2.51-2.995V35.029s-.342-1.911-1.94-1.911a7.216,7.216,0,0,0-2.339.528v7.316a3.038,3.038,0,0,1-2.481,2.966A2.852,2.852,0,0,1,30,41.075v-9.27a2.567,2.567,0,0,1,2.581-2.61,2.6,2.6,0,0,1,2.538,1.882,8.115,8.115,0,0,1,5.448-1.968C43.962,29.139,45.687,31.007,45.687,34.658Z'
                    transform='translate(224.227 896.798)'
                    fill='#1568e6'
                  />
                </g>
              </g>
            </svg>
          </div>
          <span className='tooltip'>{t("socail.linkedIn-name")}</span>
        </a>
      </li>
      <li
        className=' Instagram scr_sm:mt-4 mx-1  animate__animated fadeInUp'
        key={t("socail.Instagram-id")}>
        <a
          href={t("socail.Instagram-href")}
          target='blank'
          className='socail-media-icon flex flex-col-reverse items-center mt-4'>
          <div className=' mt-2 w-12 h-12'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              //xmlns:xlink='http://www.w3.org/1999/xlink'
              viewBox='0 0 51.5 51.5'>
              <defs>
                <linearGradient
                  id='a'
                  x1='0.5'
                  x2='0.5'
                  y2='1'
                  gradientUnits='objectBoundingBox'>
                  <stop offset='0' stopColor='#fff5b4' />
                  <stop offset='1' stopColor='#faacff' />
                </linearGradient>
              </defs>
              <g transform='translate(-302.25 -904.25)'>
                <rect
                  width='50'
                  height='50'
                  rx='5'
                  transform='translate(303 905)'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                  fill='url(#a)'
                />
                <g transform='translate(287 890.511)'>
                  <path
                    d='M39.647,56.294A16.647,16.647,0,1,1,56.294,39.647,16.647,16.647,0,0,1,39.647,56.294Zm0-25.895a9.248,9.248,0,1,0,9.248,9.248A9.248,9.248,0,0,0,39.647,30.4Z'
                    transform='translate(0 1.684)'
                    fill='#e96ff0'
                  />
                  <circle
                    cx='3.5'
                    cy='3.5'
                    r='3.5'
                    transform='translate(52.489 21.489)'
                    fill='#e2c400'
                  />
                </g>
              </g>
            </svg>
          </div>
          <span className='tooltip'>{t("socail.Instagram-name")}</span>
        </a>
      </li>
      <li
        className='Telegram scr_sm:mt-4 mx-1  animate__animated fadeInUp'
        key={t("socail.Telegram-id")}>
        <a
          href={t("socail.Telegram-href")}
          target='blank'
          className='socail-media-icon flex flex-col-reverse items-center mt-4'>
          <div className=' mt-2 w-12 h-12'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.5 51.5'>
              <g transform='translate(-387.25 -903.25)'>
                <rect
                  width='50'
                  height='50'
                  rx='5'
                  transform='translate(388 904)'
                  fill='#a1dfff'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                />
                <g transform='translate(395 911)'>
                  <rect width='36' height='36' fill='none' />
                  <path
                    d='M36.665,49.787,49.307,60.91a1.125,1.125,0,0,0,1.842-.591l5.288-23.091a1.125,1.125,0,0,0-1.5-1.294L28.973,46.13a1.128,1.128,0,0,0,.2,2.152Z'
                    transform='translate(-24.29 -30.816)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                  />
                  <line
                    y1='13.823'
                    x2='19.139'
                    transform='translate(12.375 5.147)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                  />
                  <path
                    d='M94.314,140.455l-4.388,4.387A1.125,1.125,0,0,1,88,144.055V134.9'
                    transform='translate(-75.625 -115.93)'
                    fill='none'
                    stroke='#292d32'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                  />
                </g>
              </g>
            </svg>
          </div>
          <span className='tooltip'>{t("socail.Telegram-name")}</span>
        </a>
      </li>
      <li
        className=' Behance scr_sm:mt-4 mx-1  animate__animated fadeInUp'
        key={t("socail.Behance-id")}>
        <a
          href={t("socail.Behance-href")}
          target='blank'
          className='socail-media-icon flex flex-col-reverse items-center mt-4'>
          <div className=' mt-2 w-12 h-12'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.5 51.5'>
              <g transform='translate(-450.25 -866.25)'>
                <rect
                  width='50'
                  height='50'
                  rx='5'
                  transform='translate(451 867)'
                  fill='#91c2ff'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                />
                <g transform='translate(457 865)'>
                  <path
                    d='M5.119,36.172h7.194a10.087,10.087,0,0,0,2.611-.266A5.259,5.259,0,0,0,17,35a4.161,4.161,0,0,0,1.279-1.439,5.318,5.318,0,0,0,.8-2.878,5.2,5.2,0,0,0-.693-2.665,5.7,5.7,0,0,0-.746-.853,1.182,1.182,0,0,1-.16-1.652l.16-.16A3.907,3.907,0,0,0,18.5,22.69a4.4,4.4,0,0,0-.853-2.771c-.959-1.279-2.5-1.919-4.8-1.919H5.119A1.126,1.126,0,0,0,4,19.119V35.053A1.126,1.126,0,0,0,5.119,36.172ZM7.677,22.263A1.126,1.126,0,0,1,8.8,21.144h2.878a8.135,8.135,0,0,1,2.185.266,1.7,1.7,0,0,1,1.013,1.705,1.794,1.794,0,0,1-.746,1.6,3.612,3.612,0,0,1-1.972.426H8.8a1.126,1.126,0,0,1-1.119-1.119Zm0,7.035A1.126,1.126,0,0,1,8.8,28.179h3.411a4.515,4.515,0,0,1,1.865.32,1.961,1.961,0,0,1,1.172,1.972,2.367,2.367,0,0,1-1.226,2.292,4.348,4.348,0,0,1-1.865.32H8.8a1.126,1.126,0,0,1-1.119-1.119Z'
                    fill='#0072ff'
                  />
                  <path
                    d='M46.568,22.238a1.119,1.119,0,1,0,0-2.238H40.919a1.119,1.119,0,0,0,0,2.238Z'
                    transform='translate(-16.722 -0.934)'
                    fill='#0072ff'
                  />
                  <path
                    d='M41.368,26.2a6.757,6.757,0,0,0-4.9,1.865A7.332,7.332,0,0,0,34.6,33.448c0,2.5.693,4.263,2.078,5.382a7.391,7.391,0,0,0,4.8,1.652,6.589,6.589,0,0,0,5.116-1.972c.213-.213.373-.426.533-.586a1.119,1.119,0,0,0-.906-1.812h-.426a2.988,2.988,0,0,0-2.078.8c-.053.053-.107.107-.16.107a3.023,3.023,0,0,1-1.972.639,3.418,3.418,0,0,1-1.972-.533,2.891,2.891,0,0,1-1.332-2.078.674.674,0,0,1,.64-.746h8.047a1.106,1.106,0,0,0,1.119-1.172,10.809,10.809,0,0,0-.107-1.492,6.7,6.7,0,0,0-1.066-2.771,5.568,5.568,0,0,0-2.345-1.972A7.035,7.035,0,0,0,41.368,26.2ZM43.5,31.956H39.236a.732.732,0,0,1-.693-1.013,2.475,2.475,0,0,1,.693-1.066,2.748,2.748,0,0,1,2.132-.746,3.243,3.243,0,0,1,2.132.746,2.473,2.473,0,0,1,.746,1.172A.719.719,0,0,1,43.5,31.956Z'
                    transform='translate(-14.293 -3.83)'
                    fill='#0072ff'
                  />
                </g>
              </g>
            </svg>
          </div>
          <span className='tooltip'>{t("socail.Behance-name")}</span>
        </a>
      </li>
      <li
        className='Gmail scr_sm:mt-4 mx-1  animate__animated fadeInUp'
        key={t("socail.Gmail-id")}>
        <a
          href={t("socail.Gmail-href")}
          target='blank'
          className='socail-media-icon flex flex-col-reverse items-center mt-4'>
          <div className=' mt-2 w-12 h-12'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.5 51.5'>
              <g transform='translate(-544.75 -866.75)'>
                <rect
                  width='50'
                  height='50'
                  rx='5'
                  transform='translate(545.5 867.5)'
                  fill='#fff'
                  stroke='#292d32'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                />
                <g transform='translate(554 875)'>
                  <path
                    d='M41.25,16.2l-3.125,1.719L35,20.888V31.075h4.375A1.875,1.875,0,0,0,41.25,29.2Z'
                    transform='translate(-12 -3.075)'
                    fill='#4caf50'
                  />
                  <path
                    d='M3,16.2l2.259,1.069L9.25,20.888V31.075H4.875A1.875,1.875,0,0,1,3,29.2Z'
                    transform='translate(0 -3.075)'
                    fill='#1e88e5'
                  />
                  <path
                    d='M26.375,11.2,19.5,16.356,12.625,11.2,12,14.825l.625,4.188L19.5,24.169l6.875-5.156L27,14.825Z'
                    transform='translate(-3.375 -1.2)'
                    fill='#e53935'
                  />
                  <path
                    d='M3,10.686v2.439l6.25,4.688V10L7.3,8.537A2.684,2.684,0,0,0,5.686,8h0A2.686,2.686,0,0,0,3,10.686Z'
                    fill='#c62828'
                  />
                  <path
                    d='M41.25,10.686v2.439L35,17.813V10l1.952-1.463A2.684,2.684,0,0,1,38.564,8h0A2.686,2.686,0,0,1,41.25,10.686Z'
                    transform='translate(-12)'
                    fill='#fbc02d'
                  />
                </g>
              </g>
            </svg>
          </div>
          <span className='tooltip'>{t("socail.Gmail-name")}</span>
        </a>
      </li>
    </>
  );
};
export default SocailMedia;
